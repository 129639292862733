export function defineFormatter(type, format) {
  return ({
    exclude,
    format: overrides
  }) => {
    return {
      exclude,
      format: args => {
        const formatted = format(args);
        if (exclude) {
          for (const key of exclude) {
            delete formatted[key];
          }
        }
        return {
          ...formatted,
          ...overrides(args)
        };
      },
      type
    };
  };
}
